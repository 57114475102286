import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import {
  EntitlementContext,
  ErrorContext,
  IDomainMessage,
  IDomainUpdateResponse,
  PaginatedResponse,
  ServiceResolutionData,
  ServiceResolutionError,
  ITenantDetail,
  Organizations,
  UserState,
  TenantUtilityTokens,
  TenantUtilityTokensResponse,
  ICatalogInfo,
  SortFilterContext,
  Entitlement,
  EntitylementTypes,
  Pagination,
  ResetMFA,
  PaginatedRequest,
  CampaignDetails,
  TrialBundle,
  TrialCampaignStatus,
} from '../../+state/common-admin.model';
import {
  EMPTY,
  Observable,
  TimeoutError,
  catchError,
  concatMap,
  filter,
  map,
  of,
  skipWhile,
  switchMap,
  take,
  tap,
  throwError,
  timeout,
} from 'rxjs';
import { CommandRequest, CustomerSupportEvents, TrialEvents } from 'src/app/services/command-request.service';
import { ErroMessageMap, ErrorPartMessageMapper } from 'src/app/common-constants';
import { DateTime } from 'luxon';
import { LoggerFactoryToken } from './logger.service';
import { ControlPageFacade, SignalRFacade, SnackBarFacade } from '@ra-state';
import { Router } from '@angular/router';
import { CoreConfiguration } from '@rockwell-automation-inc/service/public-api';
import * as _ from 'lodash';
import { getServiceEntitlements } from '@app/shared/utils';
import { CommandRequestBuilderService } from '@app/services/command-request-builder.service';

class ErrorContextImpl implements ErrorContext {
  url: string;
  message: string;
  correlationId: string | undefined;
  constructor(private httpError: HttpErrorResponse) {
    this.url = this.httpError.url || '';
    this.message = `${this.httpError.message}`;
    this.correlationId = String(httpError.headers.get('RequestId') || 'Not Available');
  }
}
interface ICatalogMetadata {
  productNumber: string;
  name: string;
  description: string;
  attributes: [];
  serviceKind: string;
}
@Injectable({
  providedIn: 'root',
})
export class DataService {
  baseUrl: string;
  logger = inject(LoggerFactoryToken)(DataService);
  constructor(
    private readonly http: HttpClient,
    private controlPageFacade: ControlPageFacade,
    private signalRFacade: SignalRFacade,
    private readonly router: Router,
    private snackBarFacade: SnackBarFacade,
    private commandRequestBuilderService: CommandRequestBuilderService,
    @Inject('configNav') private configService: CoreConfiguration,
  ) {
    this.baseUrl = this.configService.csApiBaseUrl;
  }

  resetMfa$(payload: ResetMFA): Observable<any> {
    const apiEndPoint = `${this.baseUrl}/customerSupport/resetmfa`;
    return this.http.post(apiEndPoint, payload);
  }

  getRecords$(recordType: string, pageRequest: EntitlementContext, sortFilterContext?: any): Observable<any> {
    let headers = new HttpHeaders();
    const url = `${this.baseUrl}/${recordType}`;
    let reqParams = new HttpParams();
    reqParams = reqParams.append('perPage', pageRequest.perPage);
    reqParams = reqParams.append('pageNum', pageRequest.pageNum);
    reqParams = reqParams.append('sortFilterOpts', JSON.stringify(sortFilterContext));
    if (pageRequest.email) {
      reqParams = reqParams.append('email', pageRequest.email);
    }

    if (pageRequest.contractNo) {
      reqParams = reqParams.append('contractNo', pageRequest.contractNo);
    }
    if (pageRequest.tenantId) {
      headers = this.setTenantIdHeader(headers, pageRequest.tenantId);
    }

    return this.http.get<PaginatedResponse<any>>(url, {
      headers: headers,
      params: reqParams,
    });
  }

  getUserPreferences$(userId: string): Observable<UserState> {
    const url = `${this.baseUrl}/users/${userId}/preferences`;
    return this.http.get<UserState>(url);
  }

  getUserOrganisations$(userId: string): Observable<Organizations[]> {
    const url = `${this.baseUrl}/users/${userId}/tenants`;
    return this.http.get<Organizations[]>(url);
  }

  getUserEntitlements$(
    userId: string,
    pageRequest: Pagination,
    sortFilterContext: SortFilterContext,
  ): Observable<PaginatedResponse<Entitlement>> {
    const url = `${this.baseUrl}/users/${userId}/entitlements?perPage=${pageRequest.perPage}&pageNum=${pageRequest.pageNum}`;
    const sortFilterModel = sortFilterContext ? sortFilterContext : {};
    return this.http
      .get<PaginatedResponse<Entitlement>>(url, {
        headers: new HttpHeaders(),
        params: {
          sortFilterOpts: JSON.stringify(sortFilterModel),
        },
      })
      .pipe(
        map((data) => {
          data.records.forEach((entitlement) => {
            //TODO: remove this piece of code when the backend provides the correct combineType
            if (_.isEmpty(entitlement.attributes?.combineType) && entitlement.serviceKind === '') {
              entitlement.serviceKind = 'UniversalCredits';
              entitlement.attributes = {
                combineType: EntitylementTypes.utilityToken,
              };
            }
          });

          return data;
        }),
        catchError((error: unknown) => this.handleError$(error)),
      );
  }

  getTenantUsers$(payload: PaginatedRequest): Observable<any> {
    let url = `${this.baseUrl}${ApiEndPoints.Tenant}users`;
    if (payload.pagination?.perPage) {
      url = `${this.baseUrl}${ApiEndPoints.Tenant}users?perPage=${payload.pagination?.perPage}&pageNum=${payload.pagination?.pageNum}`;
    }

    const sortFilterModel = payload.sortFilterContext ? payload.sortFilterContext : {};
    return this.http
      .get<PaginatedResponse<any>>(url, {
        headers: this.setTenantIdHeader(new HttpHeaders(), String(payload.tenantId)),
        params: {
          sortFilterOpts: JSON.stringify(sortFilterModel),
        },
      })
      .pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  commandRequest$(commandRequest: CommandRequest): Observable<IDomainUpdateResponse> {
    this.controlPageFacade.setLoading(true);
    return this.http
      .request(commandRequest.Method, commandRequest.ApiEndpoint, {
        body: commandRequest.RequestOptions.body,
        headers: commandRequest.RequestOptions.headers,
        observe: commandRequest.RequestOptions.observe,
      })
      .pipe(
        switchMap((response) => this.toDomainResponse$(response, commandRequest)),
        tap(() => this.controlPageFacade.setLoading(false)),
        catchError((error: unknown) => {
          this.controlPageFacade.setLoading(false);
          return this.handleApiError$(error, commandRequest);
        }),
      );
  }

  private handleApiError$(error: any, commandRequest: CommandRequest): Observable<any> {
    if (commandRequest.errorHandler$) {
      return commandRequest.errorHandler$(error);
    }
    const httpError = error as HttpErrorResponse;
    const errorMsgMap = commandRequest.ErrorMessageMap;
    switch (httpError.status) {
      case 400: {
        let message = 'The request was invalid';
        const type = 'Error';
        const errorCode = httpError.error.errorCode;
        const errorMsgMap = ErroMessageMap[errorCode];
        if (errorMsgMap) {
          message = errorMsgMap.message;
        } else {
          const errorPartMsgMap = ErrorPartMessageMapper.getMessageIconMap(errorCode);
          if (errorPartMsgMap) {
            message = errorPartMsgMap.message;
          }
        }

        this.snackBarFacade.displayMessage({
          message: message,
          type: type,
        });
        return throwError(() => error);
      }
      case 403: {
        const message = errorMsgMap?.[403] || 'Insufficient Permissions: Redirecting to dashboard';
        this.snackBarFacade.displayMessage({
          message: message,
          type: 'Error',
        });
        return throwError(() => error);
      }
      case 429: {
        const retryAfterSeconds = DateTime.fromISO(httpError.error.retryAfter).second;
        let message = `Please retry after ${retryAfterSeconds} seconds`;
        const type = 'Error';
        const errorCode = httpError.error.errorCode;
        const errorMsgMap = ErroMessageMap[errorCode];
        if (errorMsgMap) {
          message = `${errorMsgMap.message} ${message}`;
        }
        this.snackBarFacade.displayMessage({
          message: message,
          type: type,
        });
        return EMPTY;
      }
      default: {
        this.controlPageFacade.navigateToError(new ErrorContextImpl(httpError));
        return throwError(() => error);
      }
    }
  }

  private toDomainResponse$(
    response: HttpResponse<Object>,
    commandRequest: CommandRequest,
  ): Observable<IDomainUpdateResponse> {
    if (commandRequest.skipWaitingOnResponse(response.status)) {
      return of({ response } as IDomainUpdateResponse);
    }
    return this.signalRFacade.domainMessage$.pipe(map((domainMessage) => domainMessage as IDomainMessage)).pipe(
      filter((domainMessage) => {
        return domainMessage?.context.correlationId === response.headers.get('RequestId');
      }),
      concatMap((domainMessage) => {
        if (!commandRequest.IsAssistedRequest) {
          return of(domainMessage);
        }
        if (domainMessage.type === CustomerSupportEvents.ServiceRequestResolved) {
          const resolutionContext = domainMessage.data as unknown as ServiceResolutionData;
          if (resolutionContext.resolutionType.Case === 'Failed') {
            const errCode = resolutionContext.resolutionData.error;
            return throwError(() => new ServiceResolutionError(errCode));
          }
        }
        return of(domainMessage);
      }),
      skipWhile((domainMessage: IDomainMessage) => {
        this.logger.log('recd domain message', domainMessage);
        return commandRequest.notEventPredicateSatisfied(domainMessage);
      }),
      take(1),
      map((domainMessage) => {
        const domainUpdateResponse: IDomainUpdateResponse = {
          message: domainMessage,
          response: response,
        };
        return domainUpdateResponse;
      }),
      timeout(commandRequest.TimeOutInMillies),
      catchError((error: unknown) => {
        if (error instanceof TimeoutError) {
          this.snackBarFacade.displayMessage({
            message: 'Operation timeout. Please refresh.',
            type: 'Error',
          });
          return throwError(() => new Error(`Timeout for ${commandRequest}`));
        }

        if (error instanceof ServiceResolutionError) {
          const errorMsgMap = ErroMessageMap[error.errorCode];
          this.snackBarFacade.displayMessage({
            message: errorMsgMap?.message || `Service Request Failed`,
            type: 'Error',
          });
        }

        return throwError(() => error);
      }),
    );
  }

  getCatalogInfo$(): Observable<ICatalogInfo[]> {
    const url = `${this.baseUrl}${ApiEndPoints.Catalog}`;
    return this.http.get<ICatalogMetadata[]>(url).pipe(
      map((catalogResp) =>
        catalogResp.map((catalog) => {
          return {
            catalogCode: catalog.productNumber,
            description: catalog.description,
            name: catalog.name,
            attributes: catalog.attributes,
            serviceKind: catalog.serviceKind,
          } as ICatalogInfo;
        }),
      ),
      catchError((error: unknown) => this.handleError$(error)),
    );
  }

  getCampaignDetails$(campaignId: string): Observable<CampaignDetails> {
    const url = `${this.baseUrl}/TrialCampaigns/${campaignId}`;
    return this.http.get<CampaignDetails>(url).pipe(
      map((campaignDetails) => {
      return {
        ...campaignDetails,
        availableReservations: `${campaignDetails.remainingTrialsQuantity} / ${campaignDetails.trialsQuantity}`,
        status: TrialCampaignStatus[campaignDetails.status],
        createdByUser: campaignDetails.createdByUser ? campaignDetails.createdByUser : '-',
        endedByUser: campaignDetails.endedByUser ? campaignDetails.endedByUser : '-',
        pausedByUser: campaignDetails.pausedByUser ? campaignDetails.pausedByUser : '-',
      }
      }),
      catchError((error: unknown) => this.handleError$(error)));
  }

  getTrialBundles$(): Observable<TrialBundle[]> {
    const url = `${this.baseUrl}/trialbundles`;
    return this.http.get<TrialBundle[]>(url).pipe(
      map((trialbundles) => {
        trialbundles.forEach((trialbundle) => {
          const catalogCodes = trialbundle.products.reduce((catalogCodes: string[], product) => {  catalogCodes.push(product.catalog);  return catalogCodes;}, []);
          if(trialbundle.credits) {
            catalogCodes.push( String(trialbundle.credits));
          }
          trialbundle.nameAndDescription = [trialbundle.name, trialbundle.description];
          trialbundle.creditsAndProductCatalogs = catalogCodes;
        });
        return trialbundles;
      }),
      catchError((error: unknown) => this.handleError$(error)),
    );
  }

  getAllocatedTrials$(payload: PaginatedRequest): Observable<any> {
    let url = `${this.baseUrl}${ApiEndPoints.Tenant}trials`;
    if (payload.pagination?.perPage) {
      url = `${url}?perPage=${payload.pagination?.perPage}&pageNum=${payload.pagination?.pageNum}`;
    }

    const sortFilterModel = payload.sortFilterContext ? payload.sortFilterContext : {};
    return this.http
      .get<PaginatedResponse<any>>(url, {
        headers: this.setTenantIdHeader(new HttpHeaders(), String(payload.tenantId)),
        params: {
          sortFilterOpts: JSON.stringify(sortFilterModel),
        },
      })
      .pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  getUnallocatedTrials$(userId: string,  pageRequest: Pagination,
    sortFilterContext: SortFilterContext,): Observable<any> {
    let url = `${this.baseUrl}${ApiEndPoints.Users}${userId}/trials`;
    if (pageRequest.perPage) {
      url = `${url}?perPage=${pageRequest.perPage}&pageNum=${pageRequest.pageNum}`;
    }

    const sortFilterModel = sortFilterContext ? sortFilterContext : {};
    return this.http
      .get<PaginatedResponse<any>>(url, {
        headers: new HttpHeaders(),
        params: {
          sortFilterOpts: JSON.stringify(sortFilterModel),
        },
      })
      .pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  pauseCampaign$(campaignId: string): Observable<any> {
    const requestUrl = `/TrialCampaigns/${campaignId}/pause`;
    const commandRequest = this.commandRequestBuilderService
      .new(requestUrl, 'PUT', TrialEvents.CampaignPaused)
      .withWaitOn200Ok()
      .withErrorHandler((error: unknown) => {
        const httpError = error as HttpErrorResponse;
        if (httpError.error?.errorCode === 'InvalidTrialCampaign') {
          this.snackBarFacade.displayMessage({
            message: 'There was an error trying to pause the trial campaign: Invalid Trial Campaign.',
            type: 'Error',
          });
        } else {
          this.snackBarFacade.displayMessage({
            message: 'There was an error trying to pause the trial campaign: ' + httpError.error?.errorCode,
            type: 'Error',
          });
        }
        return throwError(() => error);
      });
    return this.commandRequest$(commandRequest);
  }

  resumeCampaign$(campaignId: string): Observable<any> {
    const requestUrl = `/TrialCampaigns/${campaignId}/resume`;
    const commandRequest = this.commandRequestBuilderService
      .new(requestUrl, 'PUT', TrialEvents.CampaignResumed)
      .withWaitOn200Ok();
    return this.commandRequest$(commandRequest).pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  endCampaign$(campaignId: string): Observable<any> {
    const requestUrl = `/TrialCampaigns/${campaignId}/end`;
    const commandRequest = this.commandRequestBuilderService
      .new(requestUrl, 'PUT', TrialEvents.CampaignEnded)
      .withWaitOn200Ok();
    return this.commandRequest$(commandRequest).pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  setTenantIdHeader(headers: HttpHeaders, tenantId: string): HttpHeaders {
    return headers.set('tenantid', tenantId);
  }

  getTenantDetail$(tenantId: string): Observable<ITenantDetail> {
    const url = `${this.baseUrl}${ApiEndPoints.Tenant}`;
    return this.http.get<ITenantDetail>(url, { headers: this.setTenantIdHeader(new HttpHeaders(), tenantId) }).pipe(
      map((tenantDetails) => {
        tenantDetails.entitlements = getServiceEntitlements(tenantDetails);
        return tenantDetails;
      }),
      catchError((error: unknown) => this.handleError$(error)),
    );
  }

  getTenantUtilityTokens$(tenantId: string): Observable<TenantUtilityTokens> {
    const url = `${this.baseUrl}${ApiEndPoints.UtilityTokens}`;
    return this.http
      .get<TenantUtilityTokensResponse>(url, { headers: this.setTenantIdHeader(new HttpHeaders(), tenantId) })
      .pipe(
        map((utilityToken) => {
          const tokenEnts = utilityToken.tokenEntitlements.map((ent) =>
            Object.assign({}, ent, { id: ent.entitlementId }),
          );
          const disabledTokenEnts = utilityToken.disabledTokenEntitlements.map((ent) =>
            Object.assign({}, ent, { id: ent.entitlementId }),
          );
          return {
            id: utilityToken.id,
            tokenBalance: utilityToken.tokenBalance,
            tokenExpiration: utilityToken.tokenExpiration,
            tokenEntitlements: tokenEnts,
            disabledTokenEntitlements: disabledTokenEnts,
          };
        }),
        catchError((error: unknown) => this.handleError$(error)),
      );
  }

  private handleError$(error: unknown): Observable<never> {
    this.controlPageFacade.setLoading(false);
    this.logger.error('error: ', error);
    const httpError = error as HttpErrorResponse;

    // client errors
    if (httpError.status === 400) {
      const message = 'Bad Request: The request was invalid';
      this.snackBarFacade.displayMessage({
        message: message,
        type: 'Error',
      });
      return throwError(() => error);
    }

    if (httpError.status === 403) {
      let message = 'Insufficient Permissions. Redirecting to dashboard';
      if (this.router.url === '/home') {
        message = 'Insufficient Permissions';
      }
      this.snackBarFacade.displayMessage({
        message: message,
        type: 'Error',
      });
      this.router.navigate(['/home']);
      return throwError(() => error);
    }

    this.controlPageFacade.navigateToError(new ErrorContextImpl(httpError));
    return throwError(() => error);
  }
}

export const ApiEndPoints = {
  Version: '/version',
  AccessRequest: '/accessrequests',
  Catalog: '/Catalog',
  Eula: '/eula/',
  Entitlement: '/entitlement/',
  Feedback: '/feedbacks',
  Invitations: '/invitations/',
  Notification: '/messages/',
  NotificationCounts: '/messages/count',
  SuggestedTenantByCode: '/tenants/invitecode/',
  SuggestedTenants: '/tenants/suggested/',
  Tenant: '/tenant/',
  Tenants: '/tenants/',
  Users: '/users/',
  CustomerSupport: '/customersupport/',
  UtilityTokens: '/tenant/utilitytokens',
};

export const CustomerSupportEndpoints = {
  InviteAdmin: '/customersupport/inviteuser',
};
